<template>
  <div>
    <v-simple-table fixed-header class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              <DateRangeFilter
                :label="$t('labels.created_at')"
                :placeholder="$t('labels.created_at')"
                name="created_at"
                sort-name="created_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="notifyTypeOptions"
                :label="$t('labels.classify')"
                :placeholder="$t('labels.classify')"
                name="type"
                @onFilter="onFilterChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.user_send')"
                :placeholder="$t('labels.user_send')"
                name="creator_name"
                sort-name="creator_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.user_receive')"
                :placeholder="$t('labels.user_receive')"
                name="identity_name"
                sort-name="identity_name"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter
                :label="$t('labels.title')"
                :placeholder="$t('labels.title')"
                name="title"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="imageOptions"
                :label="$t('labels.image')"
                :placeholder="$t('labels.image')"
                name="has_image"
                sort-name="has_image"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <SelectFilter
                :options="yesNoOptions"
                :label="$t('labels.status')"
                :placeholder="$t('labels.status')"
                name="status"
                sort-name="status"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
            <th role="columnheader" class="text-center">
              <DateRangeFilter
                :label="$t('labels.read_at')"
                :placeholder="$t('labels.read_at')"
                name="read_at"
                sort-name="read_at"
                :sorting="filters.sort_by"
                @onFilter="onFilterChange"
                @onSort="onSortChange"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <td>{{ formatDateTime(item.created_at) }}</td>
            <td>
              <v-chip
                x-small
                v-if="item.type"
                :color="notifyTypeColor[item.type]"
              >
                {{ $t(`labels.notify_type_${item.type}`) }}
              </v-chip>
            </td>
            <td>{{ item.creator_name || $t("labels.system") }}</td>
            <td>{{ item.identity_name }}</td>
            <td>
              <span
                class="cursor-pointer primary--text text-decoration-underline"
                @click="showDialogDetail(item)"
              >
                {{ item.title }}
              </span>
            </td>
            <td>
              <div>
                <v-avatar
                  class="ma-1"
                  size="50px"
                  v-for="(img, key) in getNotifyImages(item.images)"
                  :key="`notify_img_${key}`"
                  style="border: 1px solid #9e9e9e; border-radius: 5px"
                  @click="showImages(item.images, key)"
                >
                  <img :src="img" />
                </v-avatar>
              </div>
            </td>
            <td>
              {{ item.status === 1 ? $t("labels.read") : $t("labels.unread") }}
            </td>
            <td>{{ item.read_at ? formatDateTime(item.read_at) : "" }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
    <v-row class="pt-3">
      <v-col cols="12" class="text-right">
        <v-pagination
          v-model="page"
          :length="totalPage"
          :total-visible="6"
        ></v-pagination>
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDetail" persistent max-width="640">
      <Detail
        v-if="dialogDetail"
        @closeDialog="closeDialogDetail"
        :select-item="selectItem"
        :is-show-read-btn="false"
      />
    </v-dialog>
  </div>
</template>

<script>
import {
  IMAGE_OPTIONS,
  NOTIFY_TYPE_COLOR,
  NOTIFY_TYPE_OPTIONS,
} from "@/libs/const";
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";
import { api as viewerApi } from "v-viewer";

export default {
  name: "List",
  components: {
    DateRangeFilter: () => import("@/components/table/DateRangeFilter"),
    SelectFilter: () => import("@/components/table/SelectFilter"),
    InputFilter: () => import("@/components/table/InputFilter"),
    Detail: () => import("@/components/notification/Detail"),
  },
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    filters: {},
    sort: {},
    isLoading: false,
    dialogDetail: false,
    selectItem: {},
    employeeOptions: [],
    customerIdentities: [],
    imageOptions: [...IMAGE_OPTIONS],
    yesNoOptions: [],
    notifyTypeColor: { ...NOTIFY_TYPE_COLOR },
  }),
  computed: {
    notifyTypeOptions() {
      return [...NOTIFY_TYPE_OPTIONS].map((t) => ({
        text: this.$t(`labels.notify_type_${t}`),
        value: t,
      }));
    },
    receiverOptions() {
      return [...this.employeeOptions, ...this.customerIdentities];
    },
  },
  watch: {
    page() {
      this.getList();
    },
    filters: {
      handler() {
        this.page = 1;
        this.getList();
      },
      deep: true,
    },
  },
  created() {
    const yesNoOptions = [
      {
        text: this.$t("labels.read"),
        value: 1,
      },
      {
        text: this.$t("labels.unread"),
        value: 0,
      },
    ];
    this.yesNoOptions = [...yesNoOptions];
  },
  methods: {
    getNotifyImages(images) {
      if (images) {
        images = images.split(";");
        return images.map(
          (img) => `${process.env.VUE_APP_FILE_CDN_URL}/${img}`
        );
      }
      return [];
    },
    showImages(images, index) {
      if (!images) {
        return false;
      }
      index = index || 0;
      images = this.getNotifyImages(images);
      viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images,
      });
    },
    getList: debounce(function () {
      httpClient
        .post("/notification/v1/list-by-manager", {
          ...this.filters,
          page: this.page,
        })
        .then(({ data }) => {
          this.totalPage = data.totalPage;
          this.items = [...data.rows];
        });
    }, 500),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort };
    },
    showDialogDetail(item) {
      this.selectItem = { ...item };
      this.dialogDetail = true;
    },
    closeDialogDetail() {
      this.dialogDetail = false;
    },

    async getEmployees() {
      const { data } = await httpClient.post(
        "/employee/v1/get-by-my-warehouse",
        {
          status: 10,
        }
      );
      data.push({
        id_identity: 0,
        full_name: this.$t("labels.system"),
      });
      this.employeeOptions = [...data].map((e) => ({
        value: e.id_identity,
        text: e.full_name || "",
      }));
    },
    async getCustomerIdentity() {
      const { data } = await httpClient.post(
        "/customer/v1/get-customer-identity"
      );
      this.customerIdentities = [...data].map((e) => ({
        value: e.id,
        text: "KH: " + (e.name || ""),
      }));
    },
  },
  mounted() {
    this.getList();
    // this.getEmployees()
    // this.getCustomerIdentity()
  },
};
</script>

<style scoped></style>
